<template>
  <li>
    <Component
      :is="wrapperType"
      :to="href ? href : undefined"
      :class="itemClass"
      :target="external ? '_blank' : undefined"
    >
      <BaseIcon
        v-if="icon"
        :icon="icon"
        class="shrink-0 text-base"
      />

      <div
        v-if="status"
        :class="status && 'relative me-2'"
      >
        <slot />
        <span class="absolute -right-2 top-0 size-1.5 rounded-full bg-green-400 dark:border-gray-800" />
      </div>
      <slot v-else />
    </Component>
  </li>
</template>

<script lang="ts" setup>
import { clsx } from 'clsx/lite'
import { NuxtLink } from '#components'

const props = defineProps<{
  href?: string
  color?: string
  disabled?: boolean
  active?: boolean
  external?: boolean
  status?: boolean
  icon?: string
}>()

const wrapperType = computed(() => (props.href ? NuxtLink : 'span'))

const itemClass = computed<string>(() =>
  clsx(
    'flex items-center gap-2 px-4 py-2 text-sm',
    props.disabled ? 'text-gray-400' : 'cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white',
    props.color === 'red' && 'text-red-600 dark:text-red-400',
    props.active && 'bg-gray-100 dark:bg-gray-600 dark:text-white',
  ),
)
</script>
